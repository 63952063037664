/* eslint-disable default-case */
import { useReducer } from "react"
import services from "services"
import helpers from "utils/helpers"
import { BUSINESS_NAME } from "constants/products"
import { Reducer, INITIAL_STATE } from "./reducer"
import { getTypes } from "./utils"

const useUpload = ({ businessId: business_id }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE)

  const onSubmit = ({ file: files, type }) => {
    const [file] = files

    dispatch({
      type: "SUBMIT_FORM",
      payload: {
        file,
        type,
        business: BUSINESS_NAME[business_id],
      },
    })
  }

  const onCancelUpload = () => {
    dispatch({ type: "CONFIRM_UPLOAD_CANCEL" })
  }

  const onConfirmUpload = () => {
    dispatch({ type: "CONFIRM_UPLOAD_SUCCESS" })

    services.bases
      .upload({
        business_id,
        file: state.file,
        file_same_name: state.file_same_name,
        type: state.type,
      })
      .then(handleDocumentAnalyzing)
      .then(handleUploadBase)
  }

  const handleDocumentAnalyzing = async (data) => {
    await helpers.wait(1500)
    return data
  }

  const handleUploadBase = ({ error, code, message, errors }) => {
    switch (true) {
      case code === "warning":
        return dispatch({
          type: "CONFIRM_DUPLICATE_UPLOAD",
          payload: { message },
        })
      case Array.isArray(errors):
        return dispatch({ type: "ERRORS", payload: { errors } })
      case error:
        return dispatch({ type: "CANCEL" })
      case !error:
        return dispatch({ type: "SUCCESS" })
    }
  }

  const onNewUpload = () => {
    dispatch({ type: "NEW_UPLOAD" })
  }

  return {
    types: getTypes({ business_id }),
    state,
    onSubmit,
    onConfirmUpload,
    onCancelUpload,
    onNewUpload,
  }
}

export default useUpload
