import Icon from "components/Icon"
import If from "components/If"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"

import "./Errors.styl"

const Errors = ({ errors, file }) => (
  <If condition={errors.length}>
    <div className="errors-card" data-test="error">
      <Icon className="errors-card__icon" name="warning" />
      <Title className="errors-card__title" variant="small">
        Tivemos problemas com o upload desta base
      </Title>
      <Text className="errors-card__description">
        Encontramos <b>{errors?.length}</b> erros nesta base nas linhas abaixo.
        Corrija as linhas abaixo e tente novamente
      </Text>
      <Text className="errors-card__file">{file?.name}</Text>
      <ol className="errors-card__ordered-list">
        {errors?.map((error) => (
          <li className="errors-card__item-ordered">
            <Text className="errors-card__error-line">{error?.code}</Text>
            <ul className="errors-card__list-errors">
              {error?.errors?.map((error) => (
                <li className="errors-card__item-error">
                  <Text className="errors-card__item-description">
                    {error?.message}
                  </Text>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ol>
    </div>
  </If>
)

export default Errors
