import If from "components/If"
import SpinnerLoader from "components/SpinnerLoader"

const DocumentAnalyzing = ({ loading }) => (
  <If condition={loading}>
    <SpinnerLoader
      icon="document-analyzing"
      text="Analisando documento"
      data-test="loader"
    />
  </If>
)
export default DocumentAnalyzing
