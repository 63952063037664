import Form from "components/Forms/Form"
import Upload from "components/Forms/Upload"
import Select from "components/Forms/Select"
import Button from "components/Button"
import If from "components/If"
import Text from "components/Typography/Text"
import form from "./form"

import "./FormUpload.styl"

const FormUpload = ({ isActive, onSubmit, types = [] }) => {
  const validation = types.length ? form.validation : null

  return (
    <If condition={!isActive}>
      <Form
        className="form-upload"
        onSubmit={onSubmit}
        defaultValues={form.values}
        manually={true}
        reinitialize={true}
        schema={validation}
      >
        {({ form, setValue, getValues, watch, state: { isDisabled } }) => (
          <>
            <If condition={types.length}>
              <div className="form-upload__select-group">
                <Select
                  className="form-upload__select"
                  label="Tipo"
                  name="type"
                  form={form}
                  placeholder="Selecione..."
                >
                  {types.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.text}
                    </option>
                  ))}
                </Select>
                <Text className="form-upload__disclaimer">
                  Tamanho máximo permitido de 10MB. Arquivos permitidos: .csv
                </Text>
              </div>
            </If>
            <Upload
              name="file"
              watch={watch}
              setFieldValue={setValue}
              getValues={getValues}
              form={form}
              data-test="upload-input"
            />
            <Button
              className="form-upload__submit"
              type="submit"
              size="big"
              disabled={isDisabled || !getValues("file")?.length > 0}
              data-test="upload-button"
            >
              Carregar arquivo
            </Button>
          </>
        )}
      </Form>
    </If>
  )
}

export default FormUpload
