import { useRef } from "react"

const useUpload = ({ setFieldValue, getValues, watch, name }) => {
  const inputFile = useRef(null)
  const watchFile = watch("file", null)
  const [value] = getValues(name) || []

  const onDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    setFieldValue(name, files, { shouldDirty: true })
  }

  const onDragOver = (event) => {
    event.preventDefault()
  }

  const onUploadedFile = (event) => {
    event.preventDefault()
    const files = event.currentTarget.files
    setFieldValue(name, [...files], { shouldDirty: true })
  }

  const onFileUpload = () => {
    inputFile.current.click()
  }

  const onCleanUpload = () => {
    setFieldValue(name, [], { shouldDirty: true })
  }

  return {
    value,
    inputFile,
    onDrop,
    onDragOver,
    onUploadedFile,
    onCleanUpload,
    onFileUpload,
  }
}

export default useUpload
