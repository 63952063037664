import Button from "components/Button"
import Icon from "components/Icon"
import If from "components/If"
import Text from "components/Typography/Text"

import useUpload from "./hooks"

import "./Upload.styl"

const Upload = ({
  name = "file",
  watch,
  setFieldValue,
  accept,
  form: { register },
  getValues = () => null,
  ...props
}) => {
  const {
    value,
    inputFile,
    onDragOver,
    onDrop,
    onUploadedFile,
    onFileUpload,
    onCleanUpload,
  } = useUpload({ setFieldValue, getValues, register, watch, name })
  const { ref, ...rest } = register(name, { onChange: onUploadedFile })

  return (
    <div className="field-upload">
      <If
        condition={value}
        renderIf={
          <div className="field-upload__sent">
            <Icon className="field-upload__document" name="document" />
            <p className="field-upload__file">{value?.name}</p>
            <Icon
              className="field-upload__close"
              name="close"
              onClick={onCleanUpload}
            />
          </div>
        }
        renderElse={
          <label
            className="field-upload__label"
            htmlFor={name}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <Icon className="field-upload__cloud" name="cloud" />
            <Text className="field-upload__description">
              Arraste e solte aqui ou
            </Text>
            <Button
              variant="ghost"
              size="small"
              type="button"
              onClick={onFileUpload}
            >
              Selecionar arquivo
            </Button>
            <input
              className="field-upload__control"
              id={name}
              type="file"
              accept={accept}
              ref={(e) => {
                ref(e)
                inputFile.current = e
              }}
              {...rest}
              {...props}
            />
          </label>
        }
      />
    </div>
  )
}

export default Upload
