import Button from "components/Button"
import If from "components/If"

import "./NewUpload.styl"

const NewUpload = ({ isActive, onNewUpload }) => {
  return (
    <If condition={isActive}>
      <footer className="new-upload">
        <Button
          className="new-upload__button"
          onClick={onNewUpload}
          data-test="new-upload-button"
        >
          Novo Upload
        </Button>
      </footer>
    </If>
  )
}

export default NewUpload
