import Icon from "components/Icon"
import Text from "components/Typography/Text"

import "./SpinnerLoader.styl"

const SpinnerLoader = ({ text, icon, ...props }) => (
  <div className="spinner-loader" {...props}>
    <div className="spinner-loader__spin" />
    <Icon className="spinner-loader__icon" name={icon} />
    <Text className="spinner-loader__text">{text}</Text>
  </div>
)

export default SpinnerLoader
