import Icon from "components/Icon"
import If from "components/If"
import Text from "components/Typography/Text"

import "./ResultUpload.styl"

const ResultUpload = ({ icon, title, description, ...props }) => (
  <If condition={title}>
    <div className="result-upload" {...props}>
      <Icon className="result-upload__icon" name={icon} />
      <h1 className="result-upload__title">{title}</h1>
      <If condition={description}>
        <Text className="result-upload__description">{description}</Text>
      </If>
    </div>
  </If>
)

export default ResultUpload
