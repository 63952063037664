import Can from "components/Can"
import Card from "components/Card"
import PageHeader from "components/PageHeader"
import DocumentAnalyzing from "./components/DocumentAnalyzing"
import Errors from "./components/Errors"
import FormUpload from "./components/FormUpload"
import useUpload from "./hooks"
import ResultUpload from "./components/ResultUpload"
import NewUpload from "./components/NewUpload"
import ModalDialog from "components/ModalDialog"

import classnames from "classnames"

import "./Upload.styl"

const Upload = ({ pageContext: { business } }) => {
  const {
    types,
    state,
    onSubmit,
    onConfirmUpload,
    onCancelUpload,
    onNewUpload,
  } = useUpload({ businessId: business.business_id })

  return (
    <Can business={business.business_permission} do="upload">
      <PageHeader title="Upload" />
      <Card
        title="Upload de arquivo"
        className={classnames("upload", {
          "upload--new-upload": state.newUpload,
        })}
      >
        <FormUpload
          onSubmit={onSubmit}
          isActive={state.submited}
          types={types}
        />
        <ModalDialog
          opened={state.confirm}
          onConfirm={onConfirmUpload}
          onCancel={onCancelUpload}
          {...state.confirm}
          data-test="dialog"
        />
        <DocumentAnalyzing loading={state.loading} />
        <Errors errors={state.errors} file={state.file} />
        <ResultUpload {...state.cancel} />
        <ResultUpload {...state.success} data-test="result" />
        <NewUpload onNewUpload={onNewUpload} isActive={state.newUpload} />
      </Card>
    </Can>
  )
}

export default Upload
