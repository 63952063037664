import * as Yup from "yup"

export const values = { file: null, type: null }

export const validation = Yup.object().shape({
  type: Yup.string().required(),
})

export default {
  values,
  validation,
}
