export const INITIAL_STATE = {
  file: false,
  file_same_name: false,
  type: null,
  errors: [],
  success: false,
  cancel: false,
  loading: false,
  confirm: false,
  submited: false,
  newUpload: false,
}

export const Reducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_FORM":
      return {
        ...INITIAL_STATE,
        file: action.payload.file,
        type: action.payload.type,
        confirm: {
          icon: "document",
          title: action.payload.file.name,
          description: `Você confirma subir a base de <b>${action.payload.business}</b> ? Essa ação não pode ser desfeita`,
        },
      }
    case "CONFIRM_UPLOAD_CANCEL":
      return {
        ...state,
        confirm: null,
      }
    case "CONFIRM_UPLOAD_SUCCESS":
      return {
        ...state,
        loading: true,
        submited: true,
        confirm: null,
      }
    case "CONFIRM_DUPLICATE_UPLOAD":
      return {
        ...INITIAL_STATE,
        loading: false,
        file: state.file,
        file_same_name: true,
        type: state.type,
        confirm: {
          title: state.file.name,
          icon: "document",
          description: action.payload.message,
        },
      }
    case "SUCCESS":
      return {
        ...INITIAL_STATE,
        newUpload: true,
        submited: true,
        success: {
          icon: "sent",
          title: "Arquivo enviado com sucesso",
        },
      }
    case "ERRORS":
      return {
        ...INITIAL_STATE,
        newUpload: true,
        file: state.file,
        type: state.type,
        submited: true,
        errors: action.payload.errors,
      }
    case "CANCEL":
      return {
        ...state,
        loading: false,
        newUpload: true,
        cancel: {
          icon: "circle-error",
          title: "Upload cancelado",
        },
      }
    case "NEW_UPLOAD": {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
