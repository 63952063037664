export const TYPES = {
  7: [
    {
      value: "consig_default",
      text: "Portabilidade",
    },
    {
      value: "consig_120",
      text: "120 Parcelas",
    },
  ],
  2: [
    {
      value: "microcredito_300k",
      text: "Bases com mais de 1000 linhas",
    },
    {
      value: " ",
      text: "Bases com menos de 1000 linhas",
    },
  ],
}
